<template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
      <b-card>
        <b-row>
          <b-col
            lg="4"
            md="6"
          >
            <v-select
              v-model="selectedProvinsi"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              class="Mitra-filter-select"
              :options="provinsi"
              placeholder="provinsi"
              :clearable="false"
              @option:selected="getKota()"
            />
          </b-col>
          <b-col
            lg="4"
            md="6"
            offset-lg="4"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              block
              @click="tambahRegionBaru()"
            >
              Tambah Region Baru
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col
      v-for="region in regionList"
      :key="region.value"
      lg="4"
      md="4"
    >
      <router-link :to="{ name: 'regiondetail', params: {id: region.value} }">
        <region-card
          :kota="region.text"
          :layanan="region.layanan"
        />
      </router-link>
    </b-col>
    <vue-bottom-sheet
      ref="bottomSheetTambahRegion"
      class="p-1"
      :rounded="false"
      :click-to-close="false"
    >
      <div class="bottom-sheet">
        <div class="d-flex justify-content-between mb-1 align-items-center">
          <h5>Masukkan data wilayah</h5>
          <feather-icon
            icon="XIcon"
            size="28"
            class="text-primary"
            @click="closeBottomSheetTambahRegionBaru()"
          />
        </div>
        <v-select
          v-model="addProvinceProv"
          label="name"
          :options="provinsiOptions"
          class="Invoice-filter-select mt-2 mb-2"
          placeholder="Pilih Provinsi"
          :clearable="false"
          @option:selected="getProvinsiSelect()"
        />
        <v-select
          v-model="addRegionProv"
          label="name"
          :options="kotaOptions"
          class="Invoice-filter-select mt-2 mb-2"
          placeholder="Pilih Kota"
          :clearable="false"
        />
        <b-button
          variant="primary"
          block
          @click="tambahRegion()"
        >
          Tambahkan
        </b-button>
      </div>
    </vue-bottom-sheet>
  </b-row>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable vue/no-unused-components */
import {
  BRow,
  BCol,
  BCard,
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import Ripple from 'vue-ripple-directive'
import { db } from '@/firebase'
import provinsiJson from '@/db/province.json'
import regionJson from '@/db/region.json'
import RegionCard from './RegionCard.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    RegionCard,
    vSelect,
    VueBottomSheet,
    ToastificationContent,
  },
  data() {
    return {
      provinsi: [],
      selectedProvinsi: '',
      regionList: [],
      provinsiBottomSheet: '',
      addRegionProv: '',
      addProvinceProv: '',
      provinsiOptions: provinsiJson,
      allKota: regionJson,
      kotaOptions: [],
      regionOptions: [],
    }
  },
  created() {
    this.getProvinsi()
  },
  methods: {
    getProvinsi() {
      db.collection('region').orderBy('nama_provinsi', 'asc').get().then(snapshot => {
        snapshot.forEach(doc => {
          if (doc.id !== '00') {
            this.provinsi.push({
              value: doc.id,
              text: doc.data().nama_provinsi,
            })
          }
        })
      })
    },
    getKota() {
      db.collection('region').doc(this.selectedProvinsi.value).collection('kota').orderBy('nama_kota', 'asc')
        .get()
        .then(snapshot => {
          const arr = []
          snapshot.forEach(doc => {
            arr.push({
              value: doc.id,
              text: doc.data().nama_kota,
              layanan: doc.data().layanan,
            })
          })
          this.regionList = [...new Set(arr)]
        })
    },
    tambahRegionBaru() {
      this.$refs.bottomSheetTambahRegion.open()
    },
    closeBottomSheetTambahRegionBaru() {
      this.$refs.bottomSheetTambahRegion.close()
    },
    async getProvinsiSelect() {
      const availableKota = []

      await db.collection('region').doc(this.addProvinceProv.id).collection('kota').get()
        .then(snapshot => {
          if (!snapshot.empty) {
            snapshot.docs.forEach(doc => {
              availableKota.push({
                nama_kota: doc.data().nama_kota,
                id: doc.id,
              })
            })
          }
        })

      const listKota = this.allKota.filter(item => {
        return item.province_id === this.addProvinceProv.id
      })

      this.kotaOptions = [...new Set(listKota)]
    },
    tambahRegion() {
      const submitProv = {
        nama_provinsi: this.addProvinceProv.name,
      }
      const submitRegion = {
        nama_kota: this.addRegionProv.name,
        layanan: [
          {
            kode: null,
            layanan_tersedia: [],
            leader: null,
            status: false,
            vertikal: 'vl01',
          },
        ],
      }
      db.collection('region').doc(this.addProvinceProv.id)
        .set(submitProv)
        .then(el => {
          db.collection('region').doc(this.addProvinceProv.id).collection('kota').doc(this.addRegionProv.id)
            .set(submitRegion)
            .then(ol => {
              this.closeBottomSheetTambahRegionBaru()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Region berhasil ditambahkan',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            })
          this.addProvinceProv = [...new Set()]
          this.addRegionProv = [...new Set()]
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.bottom-sheet {
  padding: 16px 16px 100px 16px
}
</style>
